<template>
    <div class="home">
        <PreloaderComponent v-if="cart_loading || loading"/>
        <div class="main-container no-sidebar padding-bottom-30">
            <div class="container">
              <DefaultBreadCrumbs pageName="Оформление заказа"/>
                <div class="checkout">
					<div class="row">
						<div v-if="!orderPlaced" class="col">
							<div class="block-form">
                                <div class="as-block-form">
                                    <h3 class="form-heading">Детали заказа</h3>
                                    <p v-if="contracts.length>0">
                                        <n-select 
                                            placeholder="Выберите договор"
                                            :options="contracts" 
                                            v-model:value="contractID"
                                            @update:value="checkContract"
                                            :status="contractErrorStatus"
                                        />
                                    </p>
                                    <p v-if="addressList.length>0">
                                        <n-select 
                                            placeholder="Выберите адрес"
                                            :options="addressList" 
                                            v-model:value="addressID"
                                            @update:value="checkAddress"
                                            :status="addressErrorStatus"
                                        />
                                    </p>
                                    <p>
                                        <n-input
                                            v-model:value="orderComment"
                                            type="textarea"
                                            placeholder="Комментарий к заказу"
                                        />
                                    </p>
                                </div>
                                
							</div>
                            
							<div class="block-form order-review" align="right">
								<h3 class="form-heading">Ваш заказ</h3>
								<table class="shop_table">
                                    <thead>
                                        <tr>
                                            <th class="product-name">Товар</th>
                                            <th class="product-name">Остаток</th>
                                            <th class="product-name">Цена</th>
                                            <th class="product-total">Кол-во</th>
                                            <th class="product-total" style="text-align: right;">Сумма</th>
                                        </tr>
                                    </thead>
                                    <tbody id="order_table">
                                        <tr v-for="item in cart" v-bind:key="item.id">
                                            <CheckoutItemComponentVue 
                                            :goodInfo="item.good"
                                            :quantity="Number(item.quantity)"
                                            >
                                            </CheckoutItemComponentVue>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="order-total">
                                            <th></th>
                                            <th></th>
                                            <th>Итого:</th>
                                            <td></td>
                                            <td style="text-align: right;">
                                                <strong>
                                                    <span class="amount">{{ cartAmount }}</span>
                                                </strong>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
								<button @click="placeAnOrder" class="button primary">Разместить заказ</button>
							</div>
						</div>
                        <div v-else class="col">
                            <div class="col-sm-12">
                                <div class="text-center">
                                    <h2 class="title">Заказ успешно отправлен!</h2>
                                </div>
                                <div class="text-center padding-top-30">
                                    <router-link to="/" class="button primary">На главную</router-link>
                                </div>
                            </div>
                        </div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useCookies } from "vue3-cookies"
import { defineComponent, ref } from "vue"
import DefaultBreadCrumbs from '@/components/DefaultBreadCrumbs.vue'
import PreloaderComponent from '@/components/PreloaderComponent.vue'
import CheckoutItemComponentVue from '@/components/User/Checkout/CheckoutItemComponent.vue'

export default defineComponent ({
    name: "CheckoutView",
    components: {
        DefaultBreadCrumbs,
        PreloaderComponent,
        CheckoutItemComponentVue,
    },
    setup() {
        const { cookies } = useCookies()
        let contractID = ref(null)
        let addressID = ref(null)
        let orderComment = ref(null)
        let loading = ref(false)
        let orderPlaced = ref(false)
        let contractErrorStatus = ref(null)
        let addressErrorStatus = ref(null)
        
        return { 
            cookies,
            contractID,
            addressID,
            orderComment,
            loading,
            orderPlaced,
            contractErrorStatus,
            addressErrorStatus
        }
    },
    computed: {
        user () {
            return this.$store.getters.user
        },
        userToken () {
            return this.$store.getters.user_token
        },
        cart () {
            return this.$store.getters.cart
        },
        cartQty () {
            return this.$store.getters.cartQty
        },
        cart_loading () {
            return this.$store.getters.cart_loading
        },
        cartAmount () {
            return this.$store.getters.cartAmount.toFixed(2)
        },
        contracts_loading () {
            return this.$store.getters.contracts_loading
        },
        contracts () {
            if (this.$store.getters.contracts) {
                return this.$store.getters.contracts.map((item) => {
                    return {
                        label: `${item.customer.name} - ${item.organization.name}`,
                        value: item.id
                    }
                })
            }
            return []
        },
        addressList () {
            return this.getAddressesList()
        }
    },
    mounted () {
        window.scrollTo({
            top: 200,
            behavior: 'smooth'
        })
    },
    methods: {
        getAddressesList () {
            if (this.$store.getters.contracts) {
                let contract = this.$store.getters.contracts[0]
                if (contract) {
                    if (contract.client.addresses.length == 1) {
                        this.addressID = contract.client.addresses[0].id
                    }
                    return contract.client.addresses.map((item) => {
                        return {
                            label: item.address,
                            value: item.id
                        }
                    })
                }
            }
            return []
        },
        checkContract () {
            this.$store.dispatch('setOrderCreateStatusNotification', "")
            if (!this.contractID) {
                this.$store.dispatch('setOrderCreateStatusNotification', {
                    type: 'error',
                    message: 'Выберите договор!'
                })
                this.contractErrorStatus = 'error'
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
                return false
            }
            this.contractErrorStatus = null
            return true
        },
        checkAddress () {
            this.$store.dispatch('setOrderCreateStatusNotification', "")
            if (!this.addressID) {
                this.$store.dispatch('setOrderCreateStatusNotification', {
                    type: 'error',
                    message: 'Выберите адрес!'
                })
                this.addressErrorStatus = 'error'
                window.scrollTo({
                    top: 200,
                    behavior: 'smooth'
                })
                return false
            }
            this.addressErrorStatus = null
            return true
        },
        placeAnOrder () {
            if (this.checkContract() && this.checkAddress()) {
                if (this.cartQty > 0) {
                    this.loading = true
                    this.$store.dispatch('placeAnOrder', 
                    {   
                        contract_id: this.contractID,
                        cart: this.cart,
                        authToken: this.userToken,
                        orderComment: this.orderComment ? this.orderComment : "",
                        address: this.addressID
                    }).then((response) => {
                        if (response.status == "success") {
                            this.orderPlaced = true
                        }
                    }).finally(() => {
                        this.loading = false
                    })
                } else {
                    this.$store.dispatch('setOrderCreateStatusNotification', "")
                    this.$store.dispatch('setOrderCreateStatusNotification', {
                        type: 'error',
                        message: 'Не выбраны товары для оформления заказа!'
                    })
                }
            }
        }
    },
    watch: {
    '$route': {
        immediate: true,
        handler() {
            document.title = 'Оформление заказа'
        }
    },
    'addressList': {
        deep: true,
        handler() {
            document.title = 'Оформление заказа'
        }
    },
  } 
})
</script>